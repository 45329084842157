<template>
  <LandingPageLayout>
    <div class="main">
      <div class="main__jobs-find text-center">
        <div class="main__jobs-find-skills flex flex__wrap flex__center">
          <div class="main__jobs-find-skill active">Front-end</div>
          <div class="main__jobs-find-skill">Data Science</div>
          <div class="main__jobs-find-skill">Software Engineer</div>
          <div class="main__jobs-find-skill">Business Analyst</div>
          <div class="main__jobs-find-skill active">Full Stack</div>
          <div class="main__jobs-find-skill">Blockchain</div>
          <div class="main__jobs-find-skill">Web3</div>
          <div class="main__jobs-find-skill">DevOps</div>
          <div class="main__jobs-find-skill">Product Design</div>
          <div class="main__jobs-find-skill active">Swift</div>
        </div>
        <div class="flex flex__column flex__item-center">
          <h2 class="fs-36 m-fs-32 fw-600 mb-1">
            Thousand of Jobs. Find one thats right for you!
          </h2>
        </div>
      </div>

      <div class="flex flex__column flex__item-center px-7">
        <p class="text__grey mb-5 mt-2 text-center">
          Quis in nunc adipiscing dolor auctor sit dolor. Justo, viverra risus
          est ipsum scelerisque quis neque, ut accumsan. Lectus in lobortis
          cursus sit libero velit ligula.
        </p>
        <Button
          @click="$router.push({ name: 'home' })"
          class="main__jobs-find__btn"
          theme="purple"
        >
          Find a Job
        </Button>
      </div>

      <div class="main__divider mt-7"><div class="main__divider-item" /></div>

      <div class="flex flex__center">
        <div class="main__faq">
          <div class="mb-6">
            <h2 class="fs-36 fw-600 m-fs-32 mb-2 text-center">
              Frequently Asked Questions
            </h2>
            <p class="text-center text__nav-grey">
              Have some questions before you get started? Check out our FAQs
            </p>
          </div>

          <div class="mb-5">
            <div
              class="fw-500 text__nav-grey cursor-pointer"
              :class="{ 'bb-1': index !== faqs.length - 1 }"
              v-for="({ active, title, description }, index) in faqs"
              :key="index"
              @click="faqs[index].active = !faqs[index].active"
            >
              <div class="flex flex__spaced flex__item-center py-2">
                <span
                  class="text__nav-grey main__faq-title"
                  :class="{ 'color-black': active }"
                >
                  {{ title }}
                </span>
                <div>
                  <div v-if="active">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.9455 10.9716L16.8651 11.0042C17.1238 11.0053 17.3723 10.9037 17.5561 10.7216C17.7398 10.5395 17.8437 10.2918 17.8448 10.0332C17.846 9.77449 17.7444 9.52596 17.5623 9.34226C17.3802 9.15855 17.1326 9.05471 16.8739 9.05358L10.9739 9.03469C10.9739 9.02624 10.9739 9.02624 10.9739 9.02624C10.9524 9.02624 9.03701 9.02624 9.03701 9.02624L3.13706 8.99361C3.00883 8.99253 2.88166 9.01684 2.76287 9.06515C2.64408 9.11346 2.53603 9.18481 2.44495 9.27509C2.35387 9.36536 2.28156 9.47278 2.2322 9.59113C2.18284 9.70949 2.1574 9.83644 2.15734 9.96467C2.15624 10.0929 2.18054 10.2201 2.22883 10.3388C2.27712 10.4576 2.34846 10.5657 2.43872 10.6567C2.52899 10.7478 2.63639 10.8201 2.75474 10.8694C2.87308 10.9188 3.00004 10.9442 3.12827 10.9442L9.02828 10.9631L10.9455 10.9716Z"
                        fill="#A2A2A2"
                      />
                    </svg>
                  </div>
                  <div v-else>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.9652 10.9716L16.8651 11.0042C17.1238 11.0053 17.3723 10.9037 17.5561 10.7216C17.7398 10.5395 17.8437 10.2918 17.8448 10.0332C17.846 9.77449 17.7444 9.52596 17.5623 9.34226C17.3802 9.15855 17.1326 9.05471 16.8739 9.05358L10.9739 9.03469L10.9455 3.7487C10.9466 3.49003 10.9069 2.62755 10.7248 2.44384C10.5427 2.26014 10.2951 2.1563 10.0365 2.15517C9.77778 2.15404 9.52925 2.25571 9.34551 2.43782C9.16178 2.61993 9.05791 2.86756 9.05674 3.12623L9.03701 9.02624L3.13706 8.99361C3.00883 8.99253 2.88166 9.01684 2.76287 9.06515C2.64408 9.11346 2.53603 9.18481 2.44495 9.27509C2.35387 9.36536 2.28156 9.47278 2.2322 9.59113C2.18284 9.70949 2.1574 9.83644 2.15734 9.96467C2.15624 10.0929 2.18054 10.2201 2.22883 10.3388C2.27712 10.4576 2.34846 10.5657 2.43872 10.6567C2.52899 10.7478 2.63639 10.8201 2.75474 10.8694C2.87308 10.9188 3.00004 10.9442 3.12827 10.9442L9.02828 10.9631L8.99482 16.8631C8.99371 16.9913 9.01801 17.1185 9.0663 17.2373C9.1146 17.356 9.18593 17.4641 9.2762 17.5552C9.36646 17.6462 9.47386 17.7185 9.59221 17.7679C9.71056 17.8172 9.83751 17.8426 9.96574 17.8427C10.094 17.8437 10.2211 17.8194 10.3399 17.7711C10.4587 17.7228 10.5668 17.6515 10.6578 17.5612C10.7489 17.4709 10.8212 17.3635 10.8706 17.2451C10.92 17.1268 10.9454 16.9998 10.9455 16.8716L10.9652 10.9716Z"
                        fill="#A2A2A2"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <p
                class="text__nav-grey main__faq-description fs-14 lh-24"
                :class="{ active }"
              >
                {{ description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LandingPageLayout>
</template>

<script>
import LandingPageLayout from "@/layouts/LandingPageLayout";

export default {
  name: "FindAJob",
  components: {
    LandingPageLayout
  },
  data() {
    return {
      faqs: [
        {
          active: false,
          title: "Can recruiters search for candidates?",
          description:
            "Yes! If job-seekers upload their CV and fill out their candidacy profile, they will be searchable by recruiters."
        },
        {
          active: false,
          title: "Does Infohob charge a fee for using its platform?",
          description:
            "Job-seekers can use Infohob for free. However, recruiters pay a fee for using our platform. You can find the pricing here"
        },
        {
          active: false,
          title: "What industries are supported by Infohob?",
          description:
            "We support all industries in finding suitable tech talent."
        },
        {
          active: false,
          title: "What types of companies do you recruit for?",
          description:
            "From top global corporations to innovative startups, we proudly partner with companies of all sizes."
        },
        {
          active: false,
          title: "Why should I use Infohob for my recruiting needs?",
          description:
            "Infohob is at the top of the job search industry in Nigeria. We find strength in connecting tech job seekers and recruiters."
        },
        {
          active: false,
          title: "When will your online training platform be ready?",
          description:
            "We’ll be launching online courses and a full online learning management system in the next few months. Keep checking back or contact us here to be the first to know when we go live!"
        },
        {
          active: false,
          title: "What types of placements do you offer?",
          description:
            "We offer contract, contract to permanent, and permanent talent solutions."
        },
        {
          active: false,
          title: "I want to work remotely. Can you help?",
          description:
            "Yes, we have many clients looking for skilled remote workers. You can search our website for remote opportunities or get in touch for advice and support in setting yourself up to work securely from home."
        },
        {
          active: false,
          title: "Can you help me hire remote workers?",
          description:
            "Yes, we can help you quickly and easily hire skilled professionals who can work remotely from anywhere and are available and ready to work. We can also provide advice and guidance on how to set up remote employees to work securely from home. Contact us to find out more."
        },
        {
          active: false,
          title: "What’s the difference between recruitment and outsourcing?",
          description:
            "Recruitment is all about hiring--matching candidates with jobs. Our recruitment service will provide you with the people you need. Outsourcing means using us as your HR department. Our outsourcing service will provide your people with all the HR support they need after they’re hired, from onboarding and payroll to technical supervision."
        },
        {
          active: false,
          title: "What roles do you recruit for?",
          description: "We recruit for a wide range of tech roles, including:"
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss" src="./find-job.scss"></style>
